import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, IModalBase, IModalControlProps, ModalFooter } from "swiipe.portal.shared"
import "./ModalEmail.scss"

export interface IModalEmail extends IModalBase {
    type: "email"
    body: string
}

const ModalEmail = ({ title, body, callback, ...otherProps }: IModalEmail & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} modalClassName="modal-email" callback={callback} centered title={title}>
            <ModalBody>
                <div className="subject-container">
                    <p className="mb-0 text-center" dangerouslySetInnerHTML={{ __html: body }}></p>
                </div>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalEmail
