import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "reactstrap"
import {
    FieldsetFormGroup,
    FloatingLabelInput,
    PhoneNumberInput,
    addInternalCommentThunk,
    commentSelectors,
    ensureInternalCommentsThunk,
    getCompanyNumberPlaceholder,
    lookupCityByZipcodeThunk,
    organizationSelectors,
    useSelector,
    valFuncPattern,
    valFuncRequiredAndPattern,
    validationPatterns,
    validationPatternsWithLocale,
} from "swiipe.portal.shared"
import { getLatestCommentForArea } from "../../../services/commentService"
import { getFormattedDate } from "../../../services/dateTimeService"
import { StoreState } from "../../../store/StoreState"
import { merchantSelectors } from "../../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../../store/reducers/partnerReducer"
import { updateCompanyDetailsMerchant } from "../../../store/thunks/merchantThunks"
import { useReduxDispatch } from "../../../store/useReduxDispatch"
import { MerchantCompanyDetailsFormModel } from "../../../type/MerchantCompanyDetailsFormModel"
import { IEditFormBase } from "../../../type/form/IEditFormBase"
import InfoBoxContainer from "../../infobox/InfoBoxContainer"
import InfoBoxEditor from "../../infobox/InfoBoxEditor"
import { InfoBoxState } from "../../infobox/InfoBoxState"
import EditFormInput from "../input/EditFormInput"
import "./MerchantMainContactInfoForm.scss"

interface IMerchantMainContactInfoFormProps extends IEditFormBase {}

const MerchantMainContactInfoForm = ({ isInEditMode, onSwitchEditMode }: IMerchantMainContactInfoFormProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const hasMasterPartner = useSelector(partnerSelectors.partnersContainMasterPartner)
    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(state, merchantDetails?.swMerchant.registrationNumber || "")
    )
    const comments = useSelector((state: StoreState) =>
        commentSelectors.comments(state, merchantDetails?.swMerchant.swMerchantId ?? "")
    )
    const latestComment = getLatestCommentForArea(comments, "CompanyInfo")

    useEffect(() => {
        if (merchantDetails && hasMasterPartner) {
            dispatch(ensureInternalCommentsThunk(merchantDetails.swMerchant.swMerchantId, false))
        }
    }, [merchantDetails, hasMasterPartner])

    if (!merchantDetails || !merchantDetails.swMerchant) {
        return null
    }
    const merchant = merchantDetails.swMerchant
    const isMerchantDK = merchant.countryCode === "DK"

    return (
        <InfoBoxContainer
            isInEditMode={isInEditMode}
            iconClass="icon-ecommerce-account"
            title={t("merchantsettings.companyinfo")}
        >
            <InfoBoxEditor<MerchantCompanyDetailsFormModel>
                state={InfoBoxState.DEFAULT}
                iconClass="icon-ecommerce-account"
                title={t("personal.editcompanyinfo")}
                isInEditMode={isInEditMode}
                editBtnTextType="editing"
                renderPreview={() =>
                    merchant ? (
                        <div className="merchant-contact-info-preview">
                            {company && company.companyName}
                            {merchant && <br />}
                            {merchant &&
                                getCompanyNumberPlaceholder(isMerchantDK ? "DK" : "other") + ": " + merchant.registrationNumber}
                            <br />
                            {company && (
                                <>
                                    {company.companyAddress && company.companyCity && company.companyPostcode ? (
                                        <>
                                            {company.companyAddress}
                                            <br />
                                            {company.companyCity + ", " + company.companyPostcode}
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {!!company.companyPhone && company.companyPhone != "" ? (
                                        <>
                                            <br />
                                            {t("personal.phone") + ": " + company.companyPhone}
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {!!company.companyEmail && company.companyEmail != "" ? (
                                        <>
                                            <br />
                                            {t("common.email.label") + ": " + company.companyEmail}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            {latestComment?.comment && (
                                <>
                                    <br />
                                    <div className="internal-comment">
                                        <b>Internal comment: </b>
                                        <div className="internal-comment__author">
                                            by {latestComment.createdByName} ({latestComment.createdByEmail})
                                        </div>
                                        <div className="internal-comment__created">
                                            {getFormattedDate(latestComment.created, "dots")}
                                        </div>
                                        <br />
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: latestComment.comment.split("\n").join("<br/>"),
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div>
                            <strong>{t("personal.addadress")}</strong>
                        </div>
                    )
                }
                renderFormFields={(register, setValue, getValues, watch) => (
                    <div className="edit-form">
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={company?.companyName || merchant.name}
                                    name="name"
                                    disabled={!hasMasterPartner}
                                    innerRef={register(
                                        valFuncRequiredAndPattern(
                                            validationPatterns.companyName,
                                            t("personal.validationerrors.companyname")
                                        )
                                    )}
                                    placeholder={t("merchantsignup.companyname")}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={merchant.registrationNumber}
                                    name="cvr"
                                    disabled={!hasMasterPartner}
                                    innerRef={register(
                                        valFuncRequiredAndPattern(validationPatterns.cvr, t("personal.validationerrors.cvr"))
                                    )}
                                    placeholder={getCompanyNumberPlaceholder(isMerchantDK ? "DK" : "other")}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={company && company.companyAddress}
                                    name="address"
                                    innerRef={register(
                                        hasMasterPartner
                                            ? valFuncPattern(
                                                  validationPatterns.addressStreet,
                                                  t("personal.validationerrors.street")
                                              )
                                            : valFuncRequiredAndPattern(
                                                  validationPatterns.addressStreet,
                                                  t("personal.validationerrors.street")
                                              )
                                    )}
                                    placeholder={t("personal.address")}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-5">
                                <EditFormInput
                                    defaultValue={company && company.companyPostcode}
                                    name="postcode"
                                    onChange={async (e) => {
                                        const zipcode = e.currentTarget.value
                                        const city = await dispatch(lookupCityByZipcodeThunk(zipcode))
                                        if (city) {
                                            setValue("city", city, { shouldValidate: true })
                                        }
                                    }}
                                    innerRef={register(
                                        hasMasterPartner
                                            ? valFuncPattern(
                                                  validationPatternsWithLocale.addressPostalCode(
                                                      merchantDetails.swMerchant.countryCode || "DK"
                                                  ),
                                                  t("personal.validationerrors.postcode")
                                              )
                                            : valFuncRequiredAndPattern(
                                                  validationPatternsWithLocale.addressPostalCode(
                                                      merchantDetails.swMerchant.countryCode || "DK"
                                                  ),
                                                  t("personal.validationerrors.postcode")
                                              )
                                    )}
                                    placeholder={t("personal.postcode")}
                                />
                            </div>
                            <div className="col-7 pl-0">
                                <EditFormInput
                                    defaultValue={company && company.companyCity}
                                    name="city"
                                    innerRef={register(
                                        hasMasterPartner
                                            ? valFuncPattern(validationPatterns.addressCity, t("personal.validationerrors.city"))
                                            : valFuncRequiredAndPattern(
                                                  validationPatterns.addressCity,
                                                  t("personal.validationerrors.city")
                                              )
                                    )}
                                    placeholder={t("personal.city")}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <FieldsetFormGroup field>
                                    <PhoneNumberInput<MerchantCompanyDetailsFormModel>
                                        inputName="phone"
                                        defaultValue={company && company.companyPhone}
                                        preferredCountry={merchantDetails.swMerchant.countryCode.toLocaleLowerCase()}
                                        register={register}
                                        setValue={setValue}
                                        getValues={getValues}
                                        watch={watch}
                                        required={false}
                                    />
                                </FieldsetFormGroup>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12">
                                <EditFormInput
                                    defaultValue={company && company.companyEmail}
                                    name="email"
                                    innerRef={register(
                                        hasMasterPartner
                                            ? valFuncPattern(validationPatterns.email, t("merchantsignup.errmsg.email"))
                                            : valFuncRequiredAndPattern(
                                                  validationPatterns.email,
                                                  t("merchantsignup.errmsg.email")
                                              )
                                    )}
                                    placeholder={t("common.email.label")}
                                />
                            </div>
                        </Row>
                        {hasMasterPartner && (
                            <Row>
                                <div className="col-12">
                                    <FloatingLabelInput
                                        name="internalComment"
                                        type="textarea"
                                        defaultValue={latestComment?.comment ?? ""}
                                        placeholder="Internal comment (optional)"
                                        innerRef={register}
                                    ></FloatingLabelInput>
                                </div>
                            </Row>
                        )}
                    </div>
                )}
                onSave={async (model) => {
                    const merchantId = merchantDetails.swMerchant.swMerchantId
                    await dispatch(updateCompanyDetailsMerchant(merchantId, model))

                    if (hasMasterPartner && model.internalComment !== (latestComment?.comment ?? "")) {
                        await dispatch(
                            addInternalCommentThunk({
                                relationId: merchantId,
                                relationType: "SwMerchant",
                                comment: model.internalComment,
                                groupId: latestComment?.groupId,
                                webshopId: undefined,
                                area: "CompanyInfo",
                            })
                        )
                    }
                }}
                onSwitchEditMode={onSwitchEditMode}
                allowDelete={false}
            />
        </InfoBoxContainer>
    )
}

export default MerchantMainContactInfoForm
