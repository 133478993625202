import React from "react"
import { useTranslation } from "react-i18next"
import { IReleaseNote } from "../../type/releaseNotes/releaseNote"
import "./ReleaseNoteDisplay.scss"

interface IReleaseNoteDisplay {
    releaseNote: IReleaseNote
}

const ReleaseNoteDisplay = ({ releaseNote }: IReleaseNoteDisplay) => {
    const { t } = useTranslation()
    return (
        <div id="release-note-display">
            <p className="version">{releaseNote.version}</p>
            <p className="date">{releaseNote.createdDate}</p>
            <div dangerouslySetInnerHTML={{ __html: releaseNote.htmlBody }}></div>
            {releaseNote.internalHtmlBody && (
                <div>
                    <i>Internal:</i>
                    <div dangerouslySetInnerHTML={{ __html: releaseNote.internalHtmlBody }}></div>
                </div>
            )}
        </div>
    )
}

export default ReleaseNoteDisplay
