import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useCurrentRoute } from "react-navi"
import { PageWrapper, numberToFixedDecimals } from "swiipe.portal.shared"
import { useRequiredUserRelations } from "../../services/userRelationService"
import MessageBox from "./../../components/layouts/MessageBox"

interface ISalesOfferSentPageProps {}

const SalesOfferSentPage = ({}: ISalesOfferSentPageProps) => {
    useRequiredUserRelations([{ requiredType: "CallCenter" }])
    const { t } = useTranslation()
    const currentRoute = useCurrentRoute()
    return (
        <PageWrapper>
            <MessageBox title={t("sentoffer.title")}>
                <p
                    className="text-center"
                    dangerouslySetInnerHTML={{
                        __html: t("sentoffer.message", {
                            orderid: currentRoute.url.query.n || "",
                            amount: numberToFixedDecimals(currentRoute.url.query.a || "", 2) + " DKK",
                        }),
                    }}
                ></p>
                <span className="icon icon-ok my-5"></span>
                <p className="text-center h6">
                    <Link href="/sendoffer">
                        <u className="blue-logo">{t("sentoffer.linkText")}</u>
                    </Link>
                </p>
            </MessageBox>
        </PageWrapper>
    )
}

export default SalesOfferSentPage
