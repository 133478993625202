import React, { useEffect, useState } from "react"
import "./OnboardingPricingLine.scss"
import cn from "classnames"

export interface IOnboardingPricingLine {
    className?: string
    priceHtml?: string
    children?: React.ReactNode
    enlargeSpans?: boolean
}
/**
 * This component is used to display a line of text with pricing information.
 * It both supports injecting HTML as we do in legacy ways and also supports injecting React components.
 */
export const OnboardingPricingLine = ({ className, priceHtml, children, enlargeSpans }: IOnboardingPricingLine) => {
    return (
        <div className={cn("onboarding-price-line", enlargeSpans && "onboarding-price-line--enlarge-spans", className)}>
            {priceHtml && <div className={className} dangerouslySetInnerHTML={{ __html: priceHtml }}></div>}
            {children && children}
        </div>
    )
}

export default OnboardingPricingLine
