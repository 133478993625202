import cn from "classnames"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, Input } from "reactstrap"
import {
    FieldsetFormGroup,
    ShowErrorMessages,
    StandardButton,
    TextButton,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getImage } from "../../services/imageLanguageService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { viabillOnboardingLoginThunk } from "../../store/thunks/viabillThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import SubmitButton from "../buttons/SubmitButton"
import FormSectionTitle from "../form/title/FormSectionTitle"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

export interface IOnboardViabillByAuthentication {
    webshopIds: string[]
    merchantDetails: MixedMerchantDetails
    onGoBack: () => void
    onGoNext: () => void
    onOnboarded: () => void
}

interface IViabillApiKeyFormModel {
    apiKey: string
    acceptTerms: boolean
}

export const OnboardViabillByAuthentication = ({
    webshopIds,
    merchantDetails,
    onGoBack,
    onGoNext,
    onOnboarded,
}: IOnboardViabillByAuthentication) => {
    const { t } = useTranslation()
    const [showDialog, setShowDialog] = useState(true)
    const { handleSubmit, register, formState } = useForm<IViabillApiKeyFormModel>()
    const dispatch = useReduxDispatch()

    const selectedWebshopId = webshopIds?.length > 0 ? webshopIds[0] : useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length || !selectedWebshopId) {
        return null
    }
    const selectedWebshop = merchantDetails.webshops.find((w) => w.webshopId === selectedWebshopId)
    if (!selectedWebshop) {
        return null
    }

    const onSubmit: SubmitHandler<IViabillApiKeyFormModel> = async (data) => {
        try {
            await dispatch(
                viabillOnboardingLoginThunk({
                    swMerchantId: merchantDetails.swMerchant.swMerchantId,
                    webshopId: selectedWebshop.webshopId,
                    viabillApiKey: data.apiKey,
                })
            )

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <ServicesOnboardingPageWrapper customLogo={getImage("viabillLogo")}>
            {showDialog ? (
                <>
                    <FormSectionTitle
                        title={t("swiipeservicesonboarding.viabillinttext")}
                        className="section-title--bigger"
                    ></FormSectionTitle>
                    <StandardButton invertedBlue className="mt-5" onClick={() => setShowDialog(false)}>
                        {t("common.yes")}
                    </StandardButton>

                    <StandardButton dark noBorder noBorderRadius className="mt-3" onClick={() => onGoNext()}>
                        {t("common.no")}
                    </StandardButton>

                    <TextButton
                        text={t("commononboardingtexts.goback")}
                        className={cn("mt-3 mb-2")}
                        onClick={async () => onGoBack()}
                    />
                </>
            ) : (
                <>
                    <div
                        className="text-normal blue"
                        dangerouslySetInnerHTML={{
                            __html: t("viabillOnboarding.apiKeyMessage1"),
                        }}
                    />
                    <br />
                    <div
                        className="text-normal blue"
                        dangerouslySetInnerHTML={{
                            __html: t("viabillOnboarding.apiKeyMessage2"),
                        }}
                    />

                    <Form onSubmit={handleSubmit(onSubmit)} className="viabill-form mt-5">
                        <FieldsetFormGroup field>
                            <span className="text-normal blue">{t("viabillOnboarding.merchantApiKey")}</span>
                            <Input
                                name="apiKey"
                                innerRef={register(
                                    valFuncRequiredAndPattern(
                                        validationPatterns.inputText,
                                        t("viabillOnboarding.validation.missingApiKey")
                                    )
                                )}
                            />
                        </FieldsetFormGroup>

                        <ShowErrorMessages formState={formState} />

                        <SubmitButton dark noBorder containerClass="submit-btn" formState={formState}>
                            {t("commononboardingtexts.submit")}
                        </SubmitButton>
                    </Form>

                    <TextButton
                        text={t("commononboardingtexts.goback")}
                        className={cn("mt-3 mb-2")}
                        onClick={async () => setShowDialog(true)}
                    />
                </>
            )}
        </ServicesOnboardingPageWrapper>
    )
}

export default OnboardViabillByAuthentication
