import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import { FloatingLabelInput, PageContainer, PageWrapper, useSelector, valFuncRequired } from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import DankortLogo from "../../images/swCardLogos/dankort-card-logo.png"
import { getImage } from "../../services/imageLanguageService"
import { getMerchantContact } from "../../services/merchantService"
import { getProviderStatus } from "../../services/paymentService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IMerchantProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import {
    IDankortOnboardingData,
    dankortOnboardingThunk,
    getDankortOnboardingData,
    getOnboardingStatusThunk,
    setPiqOnboardingStatusThunk,
} from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./DankortOnboardingPage.scss"

interface IDankortOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}
interface IDankortOnboardingPageInnerProps extends IDankortOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const DankortOnboardingPage = (props: IDankortOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return <DankortOnboardingPageInner merchantDetails={merchantDetails} selectedWebshopId={selectedWebshopId} {...props} />
}

interface IFormModel {
    dankortId: string
}

const DankortOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: IDankortOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [showCancelService, setShowCancelService] = useState(false)

    useEffect(() => {
        merchantDetails?.webshops.forEach(
            async (w) =>
                await dispatch(
                    getOnboardingStatusThunk(merchantDetails?.swMerchant.swMerchantId, w.webshopId, false, ["CreditCard"])
                )
        )
    }, [merchantDetails])

    useEffect(() => {
        if (isEdit && selectedWebshopId) {
            ;(async () => {
                const dankortId = await dispatch(
                    getDankortOnboardingData(selectedWebshopId, merchantDetails.swMerchant.swMerchantId)
                )

                setValue("dankortId", dankortId)
            })()
        }
    }, [isEdit, selectedWebshopId])

    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, merchantDetails.swMerchant.swMerchantId)
    )

    const webshops = merchantDetails.webshops || []

    const { handleSubmit, register, setValue, formState } = useForm<IFormModel>()

    if (!webshopIds && !isEdit) {
        return null
    }

    const onSubmit: SubmitHandler<IFormModel> = async (data) => {
        try {
            const webshopids: string[] = webshopIds && webshopIds.length ? webshopIds : webshops.map((w) => w.webshopId)
            const swMerchantContact = getMerchantContact(merchantDetails)

            const formData: IDankortOnboardingData = {
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: swMerchantContact?.email || "",
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: swMerchantContact?.name || "",
                merchantContactPhone: swMerchantContact?.phone || "",
                webshopIds: webshopids.filter((wid) => {
                    const conf = onboardingStatuses ? onboardingStatuses[wid] : undefined
                    const providerStatus = getProviderStatus(conf?.gatewayProviders, "CreditCard", "DankortEpay")
                    return !providerStatus || providerStatus === "ActionRequired"
                }),
                dankortId: data.dankortId,
            }

            await dispatch(dankortOnboardingThunk(formData))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={t("dankortonboarding.dankort")}
                    cancelText={t("cancelService.dankort.cancelText")}
                    customCancelledText={t("cancelService.dankort.cancelledText")}
                    hintText={t("cancelService.dankort.hintText")}
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "CreditCard",
                                providerName: "DankortEpay",
                                providerType: "PspAndAquirer",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralCardPaymentsCancellationEmail",
                                t("serviceNames.dankort")
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit} disableDefaultPaddingBottom>
                    <div className="dankort-onboarding-container">
                        <div className="dankort-form-head">
                            <img className="swiipe-payments" src={getImage("cardPaymentsLogo")} />
                            <img className="dankort" src={DankortLogo} />
                        </div>

                        {!isEdit && <div className="dankort-text-1">{t("dankortonboarding.toactivate")}</div>}

                        {!isEdit && (
                            <div
                                className="dankort-text-2"
                                dangerouslySetInnerHTML={{ __html: t("dankortonboarding.donthaveone") }}
                            ></div>
                        )}

                        <Form onSubmit={handleSubmit(onSubmit)} className="dankort-form">
                            <span className="dankort-id-text">{t("dankortonboarding.dankortid")}</span>
                            <FloatingLabelInput
                                name="dankortId"
                                innerRef={register(valFuncRequired(t("dankortonboarding.dankortiderror")))}
                                placeholder=""
                                disabled={isEdit}
                            />

                            {!isEdit && (
                                <div className="dankort-onboarding-form mt-5">
                                    <SubmitButton dark noBorder noBorderRadius containerClass="submit-btn" formState={formState}>
                                        {t("commononboardingtexts.submit")}
                                    </SubmitButton>
                                </div>
                            )}
                        </Form>

                        {!isEdit && (
                            <div className="dankort-back-link mt-3">
                                <a
                                    onClick={() => {
                                        onGoBack()
                                    }}
                                >
                                    {t("commononboardingtexts.back")}
                                </a>
                            </div>
                        )}
                        {isEdit && (
                            <SharedCancelContainer
                                cancelText={t("dankortonboarding.cancel")}
                                onCancel={() => setShowCancelService(true)}
                            />
                        )}
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

export default DankortOnboardingPage
