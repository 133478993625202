import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FormGroupWithCheckbox, PageWrapper, StandardButton, StandardButtonWithSpinner, useSelector } from "swiipe.portal.shared"
import OkImage from "../../images/icons/ok-green.png"
import { getDateToLocalStringWithOptions, getFormattedDate } from "../../services/dateTimeService"
import { getMerchantWillBeDeactivatedDate, getMerchantWillBeDeletedDate } from "../../services/merchantService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { partnerSelectors } from "../../store/reducers/partnerReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { requestOrganizationDeletion, setOrganizationDeletedThunk } from "../../store/thunks/organizationThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TOrganizationType } from "../../type/TOrganizationType"
import { TUserRole } from "../../type/TUserRole"
import { addDays } from "../../util/dateUtil"
import PageBoxSection from "../page/PageBoxSection"
import "./CloseMerchantAccountComponent.scss"

interface ICloseMerchantAccountComponent {}

type TCloseAccountStates = "initial" | "confirmDeactivation" | "deactivationConfirmed" | "reactivated"

const CloseMerchantAccountComponent = ({}: ICloseMerchantAccountComponent) => {
    const dispatch = useReduxDispatch()

    const currenUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const hasMasterPartner = !!useSelector(partnerSelectors.partnersContainMasterPartner)
    const isOwner = currenUserRelation?.userRole === TUserRole.Owner
    const [isExpedite, setIsExpedite] = useState(false)

    const [dialogState, setDialogState] = useState("initial" as TCloseAccountStates)

    const { t } = useTranslation()

    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails) {
        return null
    }

    const CloseAccountDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.closeAccount")}</p>
                <hr />
                <p
                    className="my-4"
                    dangerouslySetInnerHTML={{
                        __html: t("closeMerchantAccount.ownerView.closeAccountMessage", {
                            merchantName: merchantDetails.swMerchant.name,
                            deactivationEndOfMonth:
                                (isExpedite ? "<b>" : "") +
                                getFormattedDate(
                                    addDays(new Date(getMerchantWillBeDeactivatedDate(isExpedite)), -1).toISOString(),
                                    "month-name-only",
                                    false,
                                    true
                                ) +
                                (isExpedite ? "</b>" : ""),
                            deletionDate:
                                (isExpedite ? "<b>" : "") +
                                getDateToLocalStringWithOptions(getMerchantWillBeDeletedDate(isExpedite), {
                                    dateStyle: "long",
                                }) +
                                (isExpedite ? "</b>" : ""),
                        }),
                    }}
                />

                {hasMasterPartner && (
                    <div className="delete-account-is-expedite__container-outer">
                        <i>{t("common.shownForMasterPartner")}</i>
                        <p className="delete-account-is-expedite__container">
                            <FormGroupWithCheckbox
                                name="enableAutoReceipt"
                                checked={isExpedite}
                                onClick={() => {
                                    setIsExpedite(!isExpedite)
                                }}
                                className="mb-1"
                            >
                                <div className="delete-account-is-expedite__title">
                                    {t("closeMerchantAccount.ownerView.expedite")}
                                </div>
                            </FormGroupWithCheckbox>
                        </p>
                    </div>
                )}

                <StandardButton
                    className="action-button"
                    isDelete
                    inverted
                    onClick={() => {
                        setDialogState("confirmDeactivation")
                    }}
                >
                    {t("closeMerchantAccount.ownerView.buttonClose")}
                </StandardButton>
            </div>
        )
    }

    const ConfirmDeactivationDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.ownerView.areYouSure")}</p>
                <hr />
                <p
                    className="mt-4 mb-2"
                    dangerouslySetInnerHTML={{
                        __html: t("closeMerchantAccount.ownerView.willBeDeactivated", {
                            deactivationEndOfMonth:
                                (isExpedite ? "<b>" : "") +
                                getFormattedDate(
                                    addDays(new Date(getMerchantWillBeDeactivatedDate(isExpedite)), -1).toISOString(),
                                    "month-name-only",
                                    false,
                                    true
                                ) +
                                (isExpedite ? "</b>" : ""),
                        }),
                    }}
                />
                <p>
                    {merchantDetails.webshops.map((w) => (
                        <>
                            {w.hostName}
                            <br />
                        </>
                    ))}
                </p>
                <p
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                        __html: t("closeMerchantAccount.ownerView.willBeDeactivated2", {
                            merchantName: merchantDetails.swMerchant.name,
                            deletionDate:
                                (isExpedite ? "<b>" : "") +
                                getDateToLocalStringWithOptions(getMerchantWillBeDeletedDate(isExpedite), {
                                    dateStyle: "long",
                                }) +
                                (isExpedite ? "</b>" : ""),
                        }),
                    }}
                />

                <div className="buttons-container">
                    <StandardButton
                        className="action-button"
                        isBlue
                        noBorder
                        onClick={() => {
                            setDialogState("initial")
                        }}
                    >
                        {t("closeMerchantAccount.ownerView.buttonNo")}
                    </StandardButton>
                    <StandardButtonWithSpinner
                        className="action-button"
                        isDelete
                        inverted
                        onClick={async () => {
                            await dispatch(
                                setOrganizationDeletedThunk(
                                    merchantDetails.swMerchant.swMerchantId,
                                    TOrganizationType.SwMerchant,
                                    true,
                                    isExpedite
                                )
                            )
                            setDialogState("deactivationConfirmed")
                        }}
                    >
                        {t("closeMerchantAccount.ownerView.buttonYes")}
                    </StandardButtonWithSpinner>
                </div>
            </div>
        )
    }

    const ConfirmedDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.ownerView.scheduledToBeClosed")}</p>
                <hr />
                <p
                    className="my-4"
                    dangerouslySetInnerHTML={{
                        __html: t("closeMerchantAccount.ownerView.willBeDeleted", {
                            deletionDate:
                                (isExpedite ? "<b>" : "") +
                                getDateToLocalStringWithOptions(getMerchantWillBeDeletedDate(isExpedite), {
                                    dateStyle: "long",
                                }) +
                                (isExpedite ? "</b>" : ""),
                        }),
                    }}
                />
                <div className="buttons-container">
                    <StandardButton
                        className="action-button"
                        isBlue
                        noBorder
                        onClick={() => {
                            setDialogState("initial")
                        }}
                    >
                        {t("common.ok")}
                    </StandardButton>
                </div>
            </div>
        )
    }

    const SendCloseRequestDialog = () => {
        const [requestSent, setRequestSent] = useState(false)

        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.closeAccount")}</p>
                <hr />
                <p className="my-4" dangerouslySetInnerHTML={{ __html: t("closeMerchantAccount.adminView.onlyOwnerCanClose") }} />

                {requestSent ? (
                    <div className="request-sent">
                        <img src={OkImage} />
                        {t("closeMerchantAccount.adminView.requestSent")}
                    </div>
                ) : (
                    <StandardButtonWithSpinner
                        className="action-button"
                        isBlue
                        noBorder
                        onClick={async () => {
                            await dispatch(
                                requestOrganizationDeletion(merchantDetails.swMerchant.swMerchantId, TOrganizationType.SwMerchant)
                            )
                            setRequestSent(true)
                        }}
                    >
                        {t("closeMerchantAccount.adminView.sendRequest")}
                    </StandardButtonWithSpinner>
                )}
            </div>
        )
    }

    const OnlyOwnerCanReactivateDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.reactivateAccount")}</p>
                <hr />
                <p className="my-4">{t("closeMerchantAccount.adminViewReactivate.reactivateMessage")}</p>
            </div>
        )
    }

    const ReactivateAccountDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.reactivateAccount")}</p>
                <hr />
                <p className="my-4">
                    {t("closeMerchantAccount.ownerView.reactivateMessage", {
                        deletionDate: getDateToLocalStringWithOptions(getMerchantWillBeDeletedDate(isExpedite), {
                            dateStyle: "long",
                        }),
                    })}
                </p>

                <StandardButtonWithSpinner
                    className="action-button"
                    noBorder
                    isBlue
                    onClick={async () => {
                        await dispatch(
                            setOrganizationDeletedThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                TOrganizationType.SwMerchant,
                                false
                            )
                        )
                        setDialogState("reactivated")
                    }}
                >
                    {t("closeMerchantAccount.ownerView.buttonReactivate")}
                </StandardButtonWithSpinner>
            </div>
        )
    }

    const ReactivatedDialog = () => {
        return (
            <div>
                <p className="heading">{t("closeMerchantAccount.ownerViewReactivated.reactivatedMessage")}</p>
                <hr />
                <p
                    className="my-4"
                    dangerouslySetInnerHTML={{
                        __html: t("closeMerchantAccount.ownerViewReactivated.reactivatedMessage2", {
                            merchantName: merchantDetails.swMerchant.name,
                        }),
                    }}
                />

                <div className="buttons-container">
                    <StandardButtonWithSpinner
                        className="action-button"
                        noBorder
                        isBlue
                        onClick={async () => {
                            setDialogState("initial")
                        }}
                    >
                        {t("common.ok")}
                    </StandardButtonWithSpinner>
                </div>
            </div>
        )
    }

    return (
        <PageWrapper className="delete-account-container">
            {merchantDetails.deletionStatus ? (
                <>
                    {!isOwner && (
                        <PageBoxSection>
                            <OnlyOwnerCanReactivateDialog />
                        </PageBoxSection>
                    )}
                    {(isOwner || hasMasterPartner) && (
                        <PageBoxSection>
                            {hasMasterPartner && !isOwner && (
                                <i>{t("closeMerchantAccount.ownerView.shownForOwnerAndMasterPartner")}</i>
                            )}
                            {dialogState === "initial" && <ReactivateAccountDialog />}
                            {dialogState === "deactivationConfirmed" && <ConfirmedDialog />}
                        </PageBoxSection>
                    )}
                </>
            ) : (
                <>
                    {!isOwner && (
                        <PageBoxSection>
                            <SendCloseRequestDialog />
                        </PageBoxSection>
                    )}
                    {(isOwner || hasMasterPartner) && (
                        <PageBoxSection>
                            {hasMasterPartner && !isOwner && (
                                <i>{t("closeMerchantAccount.ownerView.shownForOwnerAndMasterPartner")}</i>
                            )}
                            {dialogState === "initial" && <CloseAccountDialog />}
                            {dialogState === "confirmDeactivation" && <ConfirmDeactivationDialog />}

                            {dialogState === "reactivated" && <ReactivatedDialog />}
                        </PageBoxSection>
                    )}
                </>
            )}
        </PageWrapper>
    )
}

export default CloseMerchantAccountComponent
