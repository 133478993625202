import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ModalBody } from "reactstrap"
import { BaseModal, EInvoicingMarket, IModalBase, IModalControlProps, ModalFooter, useSelector } from "swiipe.portal.shared"
import { handleLegalAnchorsInModal, handleLegalPopupsInModal } from "../../services/legalService"
import { StoreState } from "../../store/StoreState"
import { ETermsType, legalSelectors } from "../../store/reducers/legalReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { ensureTermsThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { TUserRelation } from "../../type/TUserRelation"
import Spinner from "../buttons/Spinner"

export interface IModalTerms extends IModalBase {
    type: "terms"
    termsType: ETermsType
    userRelationType: TUserRelation
    relationId?: string
    fallbackMarket?: EInvoicingMarket
}

const ModalTerms = ({
    callback,
    userRelationType,
    relationId,
    termsType,
    fallbackMarket,
    ...otherProps
}: IModalTerms & IModalControlProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()

    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const resolvedRelationId =
        relationId ?? (userRelationType === currentUserRelation?.relationType ? currentUserRelation.id : undefined)
    const termsContent = useSelector((state: StoreState) => legalSelectors.terms(state, termsType, userRelationType, relationId))

    useEffect(() => {
        if (termsContent) {
            setTimeout(() => {
                // If termsContent was already fetched, then HTML is not ready for jQuery yet at this point, so wait 100ms
                handleLegalAnchorsInModal()
                handleLegalPopupsInModal(dispatch, resolvedRelationId, userRelationType, fallbackMarket)
            }, 100)
        }
    }, [termsContent])

    useEffect(() => {
        dispatch(ensureTermsThunk(termsType, userRelationType, resolvedRelationId, undefined, fallbackMarket))
    }, [termsType, userRelationType, resolvedRelationId, fallbackMarket])

    return (
        <BaseModal {...otherProps} callback={callback} size="xl" id="legal-modal" centered>
            <ModalBody>
                {!termsContent && <Spinner height={300} />}
                <div dangerouslySetInnerHTML={{ __html: termsContent || "" }} />
            </ModalBody>
            <ModalFooter rightButton={{ onClick: async () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalTerms
