import cn from "classnames"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    TitleWithFoldableHint,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FixedLabelInput from "../../components/form/input/FixedLabelInput"
import CancelService from "../../components/merchant/CancelService"
import GoBackContainer from "../../components/navigations/GoBackContainer"
import { SharedCancelContainer } from "../../components/servicesonboarding/SharedCancelContainer"
import { getImage } from "../../services/imageLanguageService"
import { getMerchantContact } from "../../services/merchantService"
import { getProviderStatus } from "../../services/paymentService"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { StoreState } from "../../store/StoreState"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IMerchantProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import {
    IVippsOnboardingData,
    getOnboardingStatusThunk,
    getVippsOnboardingDataThunk,
    setPiqOnboardingStatusThunk,
    vippsOnboardingThunk,
} from "../../store/thunks/swiipePaymentsThunks"
import { sendServiceCancellationEmailThunk } from "../../store/thunks/swiipeServicesThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./VippsOnboardingPage.scss"

interface IVippsOnboardingPageProps {
    webshopIds?: string[]
    isEdit?: boolean
    onGoBack: () => void
    onOnboarded: () => void
}
interface IVippsOnboardingPageInnerProps extends IVippsOnboardingPageProps {
    merchantDetails: MixedMerchantDetails
    selectedWebshopId?: string
}

const VippsOnboardingPage = (props: IVippsOnboardingPageProps) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const selectedWebshopId = useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length) {
        return null
    }

    return (
        <VippsOnboardingPageInner
            merchantDetails={merchantDetails}
            selectedWebshopId={selectedWebshopId}
            {...props}
        ></VippsOnboardingPageInner>
    )
}

interface IFormModel {
    vippsId: string
    vippsClientId: string
    vippsClientSecret: string
    vippsSubscriptionKey: string
}

const VippsOnboardingPageInner = ({
    merchantDetails,
    webshopIds,
    selectedWebshopId,
    isEdit,
    onGoBack,
    onOnboarded,
}: IVippsOnboardingPageInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [showCancelService, setShowCancelService] = useState(false)

    useEffect(() => {
        merchantDetails?.webshops.forEach(
            async (w) =>
                await dispatch(getOnboardingStatusThunk(merchantDetails?.swMerchant.swMerchantId, w.webshopId, false, ["Vipps"]))
        )
    }, [merchantDetails])

    useEffect(() => {
        if (isEdit && selectedWebshopId) {
            ;(async () => {
                const response = await dispatch(
                    getVippsOnboardingDataThunk(selectedWebshopId, merchantDetails.swMerchant.swMerchantId)
                )

                setValue("vippsId", response.vippsId)
            })()
        }
    }, [isEdit, selectedWebshopId])

    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, merchantDetails.swMerchant.swMerchantId)
    )

    const webshops = merchantDetails.webshops || []

    const { handleSubmit, register, setValue, errors, formState } = useForm<IFormModel>()

    if (!webshopIds && !isEdit) {
        return null
    }

    const onSubmit: SubmitHandler<IFormModel> = async (data: IFormModel) => {
        try {
            const webshopids: string[] = webshopIds && webshopIds.length ? webshopIds : webshops.map((w) => w.webshopId)
            const swMerchantContact = getMerchantContact(merchantDetails)

            const formData: IVippsOnboardingData = {
                swMerchantId: merchantDetails.swMerchant.swMerchantId,
                swMerchantEmail: swMerchantContact?.email || "",
                swMerchantName: merchantDetails.swMerchant.name,
                merchantContactName: swMerchantContact?.name || "",
                merchantContactPhone: swMerchantContact?.phone || "",
                webshopIds: webshopids.filter((wid) => {
                    const conf = onboardingStatuses ? onboardingStatuses[wid] : undefined
                    const providerStatus = getProviderStatus(conf?.gatewayProviders, "Vipps", "Vipps")
                    return !providerStatus || providerStatus === "ActionRequired"
                }),
                vippsId: data.vippsId,
                vippsClientId: data.vippsClientId,
                vippsClientSecret: data.vippsClientSecret,
                vippsSubscriptionKey: data.vippsSubscriptionKey,
            }

            await dispatch(vippsOnboardingThunk(formData, false))

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <>
            {showCancelService && (
                <CancelService
                    serviceName={t("vippsonboarding.vipps")}
                    cancelText={t("cancelService.vipps.cancelText")}
                    customCancelledText={t("cancelService.vipps.cancelledText")}
                    onCancel={async () => {
                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "Vipps",
                                providerName: "Vipps",
                                providerType: "Other",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            setPiqOnboardingStatusThunk(merchantDetails.swMerchant.swMerchantId, [selectedWebshopId!], {
                                paymentType: "Vipps",
                                providerName: "Clearhaus",
                                providerType: "Aquirer",
                                enabled: false,
                            })
                        )

                        await dispatch(
                            sendServiceCancellationEmailThunk(
                                merchantDetails.swMerchant.swMerchantId,
                                "GeneralServiceCancellationEopEmail",
                                t("serviceNames.vipps"),
                                true
                            )
                        )
                    }}
                    onGoBack={() => setShowCancelService(false)}
                />
            )}
            <PageWrapper className={cn({ "d-none": showCancelService })}>
                {isEdit && <GoBackContainer goBackText={t("commononboardingtexts.backToAllService")} onGoBack={onGoBack} />}
                <PageContainer noHeader={isEdit}>
                    <div className="vipps-onboarding-container">
                        <div className="vipps-form-head">
                            <img className="swiipe-payments" src={getImage("vippsLogo")} />
                        </div>

                        {!isEdit && <div className="vipps-text-1">{t("vippsonboarding.toactivate")}</div>}

                        {!isEdit && (
                            <div
                                className="vipps-text-2"
                                dangerouslySetInnerHTML={{ __html: t("vippsonboarding.donthaveone") }}
                            ></div>
                        )}

                        <Form onSubmit={handleSubmit(onSubmit)} className="vipps-form">
                            <FixedLabelInput
                                label={t("vippsonboarding.vippsid")}
                                name="vippsId"
                                innerRef={register(valFuncRequired(t("vippsonboarding.vippsiderror")))}
                                placeholder=""
                                disabled={isEdit}
                            />

                            {!isEdit && (
                                <>
                                    <FixedLabelInput
                                        label={t("vippsonboarding.vippssubscriptionkey")}
                                        name="vippsSubscriptionKey"
                                        innerRef={register(valFuncRequired(t("vippsonboarding.vippsSubscriptionKeyError")))}
                                        placeholder=""
                                    />

                                    <FixedLabelInput
                                        label={t("vippsonboarding.vippsclientid")}
                                        name="vippsClientId"
                                        innerRef={register(valFuncRequired(t("vippsonboarding.vippsClientIdError")))}
                                        placeholder=""
                                    />

                                    <FixedLabelInput
                                        label={t("vippsonboarding.vippsclientsecret")}
                                        name="vippsClientSecret"
                                        innerRef={register(valFuncRequired(t("vippsonboarding.vippsClientSecretError")))}
                                        placeholder=""
                                    />

                                    <TitleWithFoldableHint
                                        title={t("vippsonboarding.whereToFindInfo")}
                                        hint={<VippsGuideContent />}
                                    />

                                    <div className="vipps-onboarding-form mt-2">
                                        <ShowErrorMessages formState={formState} />

                                        <SubmitButton
                                            dark
                                            noBorder
                                            noBorderRadius
                                            containerClass="submit-btn"
                                            formState={formState}
                                        >
                                            {t("commononboardingtexts.submit")}
                                        </SubmitButton>
                                    </div>
                                </>
                            )}
                        </Form>

                        {!isEdit && (
                            <div className="vipps-back-link mt-3">
                                <a
                                    onClick={() => {
                                        onGoBack()
                                    }}
                                >
                                    {t("commononboardingtexts.back")}
                                </a>
                            </div>
                        )}
                        {isEdit && (
                            <SharedCancelContainer
                                cancelText={t("vippsonboarding.cancel")}
                                onCancel={() => setShowCancelService(true)}
                            />
                        )}
                    </div>
                </PageContainer>
            </PageWrapper>
        </>
    )
}

const VippsGuideContent = () => {
    const { t } = useTranslation()
    return (
        <div className="vipps-onboarding__hint-container">
            <p>{t("vippsonboarding.vippsGuideContext")}</p>
            <ol className="vipps-onboarding__guide-steps">
                <li dangerouslySetInnerHTML={{ __html: t("vippsonboarding.vippsGuideStep1") }}></li>
                <li>{t("vippsonboarding.vippsGuideStep2")}</li>
                <li>{t("vippsonboarding.vippsGuideStep3")}</li>
            </ol>
            <a
                className="vipps-onboarding__guide-link"
                href="https://vippsas.github.io/vipps-developer-docs/docs/vipps-developers/common-topics/api-keys#getting-the-api-keys"
                target="_blank"
                rel="noreferrer"
            >
                {t("vippsonboarding.seeTheFullGuide")}
            </a>
        </div>
    )
}

export default VippsOnboardingPage
