import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    DropDownList,
    PageContainer,
    PageWrapper,
    ShowErrorMessages,
    getKeys,
    useSelector,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import FileInput, { megabyteInBytes } from "../../components/form/input/FileInput"
import WebshopSelect, { useInitialWebshopValuesForWebshopSelect } from "../../components/merchant/WebshopSelect"
import { useRequiredUserRelations } from "../../services/userRelationService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IGetCsvTransformed, SettlementType, getCsvTransformedFileThunk } from "../../store/thunks/transactionThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import "./BookkeepingPage.scss"

interface IBookkeepingPage {}
interface IBookkeepingPageInner {
    merchantDetails: MixedMerchantDetails
}

const BookkeepingPage = ({}: IBookkeepingPage) => {
    useRequiredUserRelations([{ requiredType: "Merchant" }])
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)

    if (!merchantDetails || merchantDetails.webshops.length === 0) {
        return null
    }

    return <BookkeepingPageInner merchantDetails={merchantDetails}></BookkeepingPageInner>
}

interface IFormData {
    file: FileList
    acquirer: SettlementType
}

const BookkeepingPageInner = ({ merchantDetails }: IBookkeepingPageInner) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [exportAllData, setExportAllData] = useState(false)

    const { initialWebshopId } = useInitialWebshopValuesForWebshopSelect(merchantDetails)
    const [selectedWebshopId, setSelectedWebshopId] = useState(initialWebshopId)

    const { handleSubmit, register, formState, getValues, setError, clearErrors, trigger, setValue } = useForm<IFormData>()

    if (!merchantDetails) {
        return null
    }

    const onSubmit: SubmitHandler<IFormData> = async (data) => {
        try {
            const files = getKeys(data.file).map((key) => data.file[key])

            const formData: IGetCsvTransformed = {
                webshopId: selectedWebshopId,
                exportAllData: exportAllData,
                settlementType: data.acquirer,
                files: files,
            }

            await dispatch(getCsvTransformedFileThunk(formData))
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <PageWrapper>
            <PageContainer>
                <div className="bookkeeping-main-container">
                    <p className="text-center header-text mb-4">{t("bookkeeping.title")}</p>
                    <p className="text-center mb-4">{t("bookkeeping.togettransactionids")}</p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bookkeeping-form">
                            <FileInput
                                name={"file"}
                                triggerValidation={trigger}
                                maxFileSizeBytes={megabyteInBytes * 10}
                                clearError={clearErrors}
                                setError={setError}
                                setValue={setValue}
                                getValues={getValues}
                                accept=".csv"
                                minFilesAmount={1}
                                maxFilesAmount={5}
                                multiple
                                register={register}
                                requiredErrorMessage={t("bookkeeping.uploaderror")}
                            ></FileInput>

                            <WebshopSelect
                                merchantDetails={merchantDetails}
                                onWebshopChange={(selectedWebshopId: string) => {
                                    setSelectedWebshopId(selectedWebshopId)
                                }}
                                hidePlatform
                            ></WebshopSelect>

                            <p className="select-acquirer-heading mb-2">{t("bookkeeping.selectacquirer")}</p>
                            <DropDownList
                                className="mb-3"
                                options={[{ value: 0, text: "Clearhaus" }]}
                                name="acquirer"
                                innerRef={register(valFuncRequired(t("bookkeeping.selectacquirererror")))}
                            ></DropDownList>

                            <ShowErrorMessages formState={formState} />
                        </div>
                        <div className="bookkeeping-submit">
                            <SubmitButton
                                onClick={() => setExportAllData(true)}
                                dark
                                noBorder
                                noBorderRadius
                                containerClass="submit-btn"
                                formState={formState}
                                disableSpinner={!exportAllData}
                                className="mb-2"
                            >
                                {t("bookkeeping.exportalldata")}
                            </SubmitButton>
                            <SubmitButton
                                onClick={() => setExportAllData(false)}
                                dark
                                noBorder
                                noBorderRadius
                                containerClass="submit-btn"
                                formState={formState}
                                disableSpinner={exportAllData}
                            >
                                {t("bookkeeping.exportpartialdata")}
                            </SubmitButton>
                        </div>
                    </Form>
                    <div className="disclamer mt-4">{t("bookkeeping.disclamerBottom")}</div>
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default BookkeepingPage
